
import { Component, Vue } from "vue-property-decorator";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

@Component
export default class AttachmentsComponent extends Vue {
  bottomSheet = false;

  tiles = [
    {
      icon: "mdi-camera-outline",
      title: this.$t("EREPORTER.EXPENSE.TAKE_PHOTO"),
      method: this.uploadPhoto,
      methodValue: CameraSource.Camera
    },
    {
      icon: "mdi-file-image-outline",
      title: this.$t("EREPORTER.EXPENSE.ADD_PHOTO"),
      method: this.uploadPhoto,
      methodValue: CameraSource.Photos
    },
    {
      icon: "mdi-arrow-left",
      title: this.$t("BUTTON.CANCEL"),
      method: this.toggleBottomSheet,
      methodValue: false
    }
  ];

  async uploadPhoto(source) {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: source
    });

    if (photo.webPath) {
      const blob = await fetch(photo.webPath).then(r => r.blob());
      this.$emit("attach", { blob: blob, path: photo.webPath });
    }

    this.toggleBottomSheet(false);
  }

  toggleBottomSheet(value: boolean) {
    this.bottomSheet = value;
  }
}
